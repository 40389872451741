<template>
  <article class="flex flex-col justify-center gap-6">
    <h3 class="text-center text-2xl font-bold text-green-700 sm:text-3xl">
      PRODUCTOS
    </h3>
    <Flicking :options="options" ref="flicking">
      <div
        v-for="(categoriaIteracion, index) in categorias"
        :key="index"
        class="my-2 md:hover:scale-110 ease-in duration-200"
        :class="{
          'ml-auto mr-3 md:mr-4 lg:mr-5': index === 0,
          'mr-auto ml-3 md:ml-4 lg:ml-5': index === categorias.length - 1,
          'mx-3 md:mx-4 lg:mx-5':
            index !== 0 && index !== categorias.length - 1,
        }"
      >
        <button @click="onCategoriaClick(categoriaIteracion, index)">
          <picture>
            <source
              :srcset="`/assets/categoriaCarrusel/${categoriaIteracion.replace(
                '-',
                '_',
              )}.webp`"
              type="image/webp"
            />
            <source
              :srcset="`/assets/categoriaCarrusel/${categoriaIteracion.replace(
                '-',
                '_',
              )}.jpg`"
              type="image/jpg"
            />
            <img
              :alt="categoriaIteracion"
              class="w-auto"
              width="183"
              height="136"
            />
          </picture>
        </button>
        <div
          class="mx-3 h-1 w-auto rounded-full bg-green-700"
          :class="categoria !== categoriaIteracion ? 'hidden' : ''"
        ></div>
      </div>
    </Flicking>
    <div id="carrusel-categorias" v-if="productosFiltrados.length">
      <CarruselProductos
        :loading="loading"
        :productos="productosFiltrados"
        parent="carrusel-categorias"
        ref="carrusel_roductos_ref"
      />
    </div>
    <div class="text-center my-6" v-else>
      No hay productos disponibles para esta categoría en el despacho
      seleccionado.
    </div>
    <a
      class="mt-4 mb-7 place-self-center self-center text-center text-base font-bold text-green-500 underline md:hover:text-green-700 focus:text-green-700"
      :href="`/categoria/${categoria}`"
      v-if="productosFiltrados.length"
    >
      VER TODO {{ categoria.toUpperCase().replace("-", " ") }}
    </a>
  </article>
</template>

<script setup lang="ts">
  import CarruselProductos from "@components/carruseles/CarruselProductos.vue";
  import { ref, watch, computed } from "@vue/runtime-core";
  import Flicking from "@egjs/vue3-flicking";
  import { getProductosCategoriaCarrusel } from "@api/client/productos";
  import { getCookie } from "@lib/cookies";
  import type { Producto } from "@lib/interfaces";
  import { useStore } from "@nanostores/vue";
  import { actualizarPrecioOfertaStockDisponibleProductos } from "@stores/app";
  import { ventanaActiva } from "@stores/despacho";

  const props = defineProps<Props>();
  const carrusel_roductos_ref = ref<{
    reiniciarCarrusel: () => void;
  }>();
  const loading = ref<boolean>(false);
  const categorias = ref([
    "despensa",
    "verduras",
    "frutas",
    "frutos-secos",
    "hogar",
    "huevos",
  ]);
  const flicking = ref<Flicking>();
  const categoria = ref("despensa");
  const productos_carrusel = ref<Producto[]>([]);
  productos_carrusel.value = props.productos;

  const options = {
    resizeDebounce: 120,
    bound: true,
    preventDefaultOnDrag: true,
    align: "center",
  };
  interface Props {
    productos: Producto[];
  }
  const $ventanaActiva = useStore(ventanaActiva);

  const onCategoriaClick = async (categoriaEntrante: string, index: number) => {
    if (categoriaEntrante === categoria.value) return;
    if (window.innerWidth < 1280) flicking.value?.moveTo(index);
    loading.value = true;
    carrusel_roductos_ref.value?.reiniciarCarrusel();
    categoria.value = categoriaEntrante;
    await getProductosCategoria();
  };

  const getProductosCategoria = async () => {
    const cookies = document.cookie;

    const { productos } = await getProductosCategoriaCarrusel(
      categoria.value,
      getCookie("bodega", cookies),
      getCookie("ventana", cookies),
    );
    if (!productos) return;
    productos_carrusel.value = productos;
    loading.value = false;
  };

  const productosFiltrados = computed(() => {
    return productos_carrusel.value.filter((producto) => {
      return producto.activo_bodega && producto.stock_disponible > 0;
    });
  });

  watch($ventanaActiva, async () => {
    actualizarPrecioOfertaStockDisponibleProductos(productos_carrusel.value);
  });
</script>
